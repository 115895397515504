<template>
  <div class="report--cart">
    <div class="view pb-2">
      <div class="w-100">
        <h5 class="d-inline-block"><b>{{ $t('system.settlements') }}</b></h5>
        <dropdown-configurator
            :columns="settlementsThead"
            :searchString.sync="searchString"
            :selectedColumns="selectedSettlementsThead"
            @submitSearch="fetchData"
            @update:selectedColumns="onChangeSelectedColumns"
            class="d-inline-block float-right"
            disableFilters
            position="left"
        >
        </dropdown-configurator>
      </div>
      <el-tag
          size="small"
          type="primary"
      >
        <i class="el-icon-goods" /> {{ countOfSelectedSettlements }}
      </el-tag>
    </div>
    <checkable-table
        :align-left="['settlements.name']"
        :data="settlements"
        :editable-columns="['settlements.name']"
        :isCheckedAll.sync="isCheckedAll"
        :pageSizes="[10, 30, 50, 100]"
        :params.sync="params"
        :selectedIds="selectedSettlementsIds"
        :thead="selectedSettlementsThead"
        :total="totalSettlementsCount"
        @clearAllSelections="clearAllSettlementsSelections"
        @editCell="updateData"
        @selectionChange="handleSelectionChange"
        id-column="settlements.id"
        pagination
    >
    </checkable-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import CheckableTable from './checkableTable.vue';
import DropdownConfigurator from '../../components/DropdownConfigurator/crmDropdownConfigurator.vue';

export default {
  name: 'settlementsCard',
  components: {
    CheckableTable,
    DropdownConfigurator,
  },
  beforeMount() {
    this.fetchSettlements();
  },
  computed: {
    ...mapGetters('settled_reports', [
      'selectedSettlementsIds',
      'settlementsThead',
      'selectedSettlementsThead',
      'settlements',
      'isCheckedAllSettlements',
      'totalSettlementsCount',
      'settlementsListParams',
      'settlementsListSearchString',
      'countOfSelectedSettlements',
    ]),
    isCheckedAll: {
      get() {
        return this.isCheckedAllSettlements;
      },
      async set(value) {
        await this.selectAllSettlements(value);
        this.fetchTimeEntries();
      },
    },
    params: {
      get() {
        return this.settlementsListParams;
      },
      async set(value) {
        await this.setSettlementsListParams(value);
        this.fetchData();
      },
    },
    searchString: {
      get() {
        return this.settlementsListSearchString;
      },
      set(value) {
        this.setSettlementsListSearchString(value);
      },
    },
  },
  methods: {
    ...mapActions('settled_reports', [
      'setSettlementSelectionChange',
      'setSelectedSettlementsThead',
      'selectAllSettlements',
      'fetchSettlements',
      'fetchTimeEntries',
      'setSettlementsListParams',
      'setSettlementsListSearchString',
      'clearAllSettlementsSelections',
      'updateSettlementData',
    ]),
    async onChangeSelectedColumns(columns) {
      await this.setSelectedSettlementsThead(columns);
      await this.fetchData();
    },
    async updateData(data) {
      await this.updateSettlementData(data);
      await this.fetchData();
    },
    async handleSelectionChange(id) {
      await this.setSettlementSelectionChange(id);
      await this.fetchTimeEntries();
    },
    async fetchData() {
      await this.fetchSettlements();
    },
  },
};
</script>

<style scoped>

</style>
