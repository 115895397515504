<template>
  <div>
    <div
        class="table-responsive"
        style="max-height: 1000px;"
        v-if="thead && thead.length > 0"
    >
      <table class="table mb-0">
        <thead>
        <tr>
          <th class="table--th-view d-flex justify-content-center table--th-static">
              <span class="d-flex align-items-center">
                  <el-checkbox
                      :disabled="data.length === 0"
                      :value="isCheckedAll"
                      @change="checkAllRows"
                      style="outline: none"
                  >
                  </el-checkbox>
              </span>
          </th>
          <th
              :key="index"
              @click="sort(item)"
              class="table--th-view"
              v-for="(item, index) in thead"
          >
            <div class="d-flex align-items-center">
              <span
                  :style="isLimitedWidth(item) ? 'text-overflow: ellipsis; overflow: hidden; max-width: ' +  isLimitedWidth(item).maxWidth : ''"
              >{{ $t(item) }}</span>
              <div class="d-flex flex-column table-sort-icons">
                <span v-if="item === params.column">
                  <font-awesome-icon
                      :class="params.direction === 'asc' ? 'active' : ''"
                      class="caret-sort caret-sort-up"
                      icon="caret-up"
                  />
                  <font-awesome-icon
                      :class="params.direction === 'desc' ? 'active' : ''"
                      class="caret-sort caret-sort-down"
                      icon="caret-down"
                  />
                </span>
                <span v-else>
                  <font-awesome-icon
                      class="caret-sort caret-sort-up"
                      icon="caret-up"
                  />
                  <font-awesome-icon
                      class="caret-sort caret-sort-down"
                      icon="caret-down"
                  />
                </span>
              </div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            :key="row[idColumn]"
            v-for="row in data"
        >
          <td class="table--tbody table--td-static">
            <div class="px-2">
              <el-checkbox
                  :value="selectedIds.includes(row[idColumn])"
                  @change="handleSelectionChange(row[idColumn])"
              >
              </el-checkbox>
            </div>
          </td>
          <td
              :key="index"
              :style="isLimitedWidth(item) ? 'max-width: ' + isLimitedWidth(item).maxWidth : ''"
              class="table--tbody pointer"
              v-for="(item, index) in thead"
          >
            <div
                :style="alignLeft.includes(item) ? 'text-align: left' : ''"
                class="p-0"
                v-if="isEditable(row[idColumn], item)"
            >
              <el-input
                  @keyup.enter.native="saveEditableCell"
                  @keyup.esc.native="closeEditableCell"
                  autofocus
                  class="table--editable-input"
                  placeholder="000:00"
                  size="mini"
                  v-if="editableCell.col === 'time_entries.overworked_time'"
                  v-mask="'###:##'"
                  v-model="editableCell.value"
              >
                <i
                    @click="saveEditableCell"
                    class="el-icon-success el-input__icon"
                    slot="suffix"
                    style="color: #67C23A; cursor: pointer"
                >
                </i>
                <i
                    @click="closeEditableCell"
                    class="el-icon-error el-input__icon"
                    slot="suffix"
                    style="color: #F56C6C; margin-right: 3px; cursor: pointer"
                >
                </i>
              </el-input>
              <el-input
                  @keyup.enter.native="saveEditableCell"
                  @keyup.esc.native="closeEditableCell"
                  autofocus
                  class="table--editable-input"
                  size="mini"
                  v-else
                  v-model="editableCell.value"
              >
                <i
                    @click="saveEditableCell"
                    class="el-icon-success el-input__icon"
                    slot="suffix"
                    style="color: #67C23A; cursor: pointer"
                >
                </i>
                <i
                    @click="closeEditableCell"
                    class="el-icon-error el-input__icon"
                    slot="suffix"
                    style="color: #F56C6C; margin-right: 3px; cursor: pointer"
                >
                </i>
              </el-input>
            </div>
            <!--            <div v-else-if="isCheckable" class="px-2">-->
            <!--              <el-checkbox :value="selectedIds.includes(row[idColumn])"-->
            <!--                           @change="handleSelectionChange(row[idColumn])">-->
            <!--              </el-checkbox>-->
            <!--            </div>-->
            <div
                :class="alignLeft.includes(item) ? 'justify-content-start' : 'justify-content-center'"
                @click="onRowClick(row, item)"
                class="d-flex align-items-center h-100"
                v-else
            >
              <div :style="isLimitedWidth(item) ? 'text-overflow: ellipsis; overflow: hidden;' : ''">
                <template v-if="(item === 'time_entries.overworked_time' && row['time_entries.overworked_time']) || (item === 'time_entries.hours' && row['time_entries.hours'])">
                  {{ HHmmssToHHHmm(row[item]) }}
                </template>
                <template v-else-if="item === 'issueEstimatedHours'">
                  {{ row[item] | removeSeconds }}
                </template>
                <template v-else-if="item === 'time_entriesCreatedAt'">
                  {{ row[item] | formatDataFromYearToDay }}
                </template>
                <template v-else-if="item.split('.')[1].startsWith('is_')">
                  <el-checkbox
                      :false-label="0"
                      :true-label="1"
                      @change="$emit('editCell', {id: row[idColumn], col: item, value: row[item]})"
                      style="margin-top: 6px"
                      v-model="row[item]"
                  />
                </template>
                <template
                    class="px-2"
                    v-else
                >
                  {{ row[item] }}
                </template>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <span class="no-date-title m-3">{{ $t('system.no_selected_column') }}</span>
    </div>
    <div v-if="data && data.length === 0 && thead && thead.length > 0">
      <span class="no-date-title m-3">{{ $t('system.no_data') }}</span>
    </div>
    <el-card
        class="pagination-container m-2 py-3"
        shadow="never"
        v-if="pagination"
    >
      <el-pagination
          :current-page="params.page"
          :page-size="params.per_page"
          :page-sizes="pageSizes"
          :pager-count="5"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="sizes, prev, pager, next"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
// import _ from 'lodash'
import moment from 'moment';
// import VueMask from 'v-mask'
import time from '../../mixin/time';
import issueMixin from '../../mixin/issue';
import {HHmmssToHHHmm} from '../../helpers/time';
// import EventBus from '../../eventBus'

export default {
  name: 'checkableTable',
  mixins: [time, issueMixin],
  props: {
    params: {
      type: Object,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 30, 50, 100],
    },
    idColumn: {
      type: String,
      default: 'id',
    },
    alignLeft: {
      type: Array,
      default: () => [],
    },
    thead: {
      type: [Array, Object],
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    selectedIds: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    isCheckedAll: {
      type: Boolean,
      required: true,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    editableColumns: {
      type: Array,
      default: () => [],
    },
    limitColumnsWidth: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clickCount: 0,
      editableCell: {
        id: null,
        col: null,
        value: null,
      },
    };
  },
  // beforeMount() {
  //   this.fetchData()
  // },
  filters: {
    removeSeconds: function (str) {
      if (str) return str.slice(0, -3);
    },
    formatDataFromYearToDay(data) {
      return data ? moment(data).format('YYYY-MM-DD') : null;
    },
  },
  watch: {
    // 'editableCell.value'(newVal, oldVal) {
    //   if ('time_entries.overworked_time')
    // }
  },
  methods: {
    //row[idColumn], item, row[item]
    // id col value
    onRowClick(row, col) {
      this.clickCount++;
      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
          if (row['issues.id']) {
            this.openIssueModal(row['issues.id']);
          }
        }, 500);
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer);
        this.clickCount = 0;
        this.enableEdit(row[this.idColumn], col, row[col]);
      }
    },
    openIssueModal(id) {
      // set only unique ids
      const issuesIds = [...new Set(
          this.data.map(item => item['issues.id']),
      )];
      this.openIssue(id, issuesIds);
    },
    isEditable(id, col) {
      if (this.editableCell) {
        return (id === this.editableCell.id && col === this.editableCell.col);
      }
      return false;
    },
    isLimitedWidth(column) {
      // console.log('this.limitColumnsWidth', this.limitColumnsWidth)
      // console.log('column', column)
      // if (_.find(['column', column], this.limitColumnsWidth)) {
      //   console.log('is', true)
      // }
      return this.limitColumnsWidth.find(item => item.column === column);
    },
    closeEditableCell() {
      this.editableCell = {
        id: null,
        col: null,
        value: null,
      };
    },
    async saveEditableCell() {
      if (this.editableCell.col === 'time_entries.overworked_time') {
        if (this.checkHHHmmFormat(this.editableCell.value)) {
          let editabelCell = this.editableCell;
          editabelCell.value = this.hhhMmToTime(editabelCell.value);
          await this.$emit('editCell', editabelCell);
          this.closeEditableCell();
        }
      } else {
        await this.$emit('editCell', this.editableCell);
        this.closeEditableCell();
      }
    },
    HHmmssToHHHmm(value) {
      return HHmmssToHHHmm(value);
    },
    enableEdit(id, col, value) {
      if (this.editableColumns && this.editableColumns.includes(col)) {
        this.editableCell.id = id;
        this.editableCell.col = col;
        this.editableCell.value = col === 'time_entries.overworked_time' ? this.HHmmssToHHHmm(value) : value;
      }
    },
    fetchData() {
      this.$emit('update:params', this.params);
    },
    checkAllRows(value) {
      this.$emit('update:isCheckedAll', value);
    },
    handleSelectionChange(id) {
      this.$emit('selectionChange', id);
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    sort(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchData();
    },
    // time2sec(time) {
    //   return time.split(':').reduce((acc, time) => (60 * acc) + +time)
    // }
    // converTime(time) {
    //   const sec = time.split(':').reduce((acc, time) => (60 * acc) + +time)
    //   let mins = sec / 60;
    //   const hours = mins / 60;
    //   const intHours = Math.floor(hours);
    //   mins = (hours - intHours) * 60;
    //   const zerosAddToHours = 3 - intHours.toString().length;
    //   const zerosAddToMins = 2 - mins.toString().length
    //   let newFormatTimeString = '';
    //   for (let i = 0; i < zerosAddToHours; i++) {
    //     newFormatTimeString += '0'
    //   }
    //   newFormatTimeString += intHours.toString()
    //   newFormatTimeString += ':'
    //   for (let i = 0; i < zerosAddToMins; i++) {
    //     newFormatTimeString += '0'
    //   }
    //   newFormatTimeString += mins.toString();
    //   return newFormatTimeString
    // }
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.table--tbody {
  .table--editable-input {
    input {
      padding-right: 50px;
    }
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import '../../static/css/table';

.table--th-static, .table--td-static {
  width: 50px;
  padding-bottom: 0;

  .el-checkbox {
    margin: 0;
  }
}
</style>
