<template>
  <div class="report--cart">
    <!--    <div slot="header">-->
    <div class="view pb-2">
      <div class="d-flex justify-content-between">
        <h5><b>{{ $t('system.time_entries') }}</b></h5>
        <dropdown-configurator
            :columns="timeEntriesThead"
            :filters="timeEtriesFilters"
            :searchString.sync="searchString"
            :selectedColumns="selectedTimeEntriesThead"
            @clearFilters="clearTimeEntriesFilters"
            @setCurrentColumnsConfig="setCurrentColumnsConfig"
            @submitSearch="setFiltersAndFetchData"
            @update:selectedColumns="setSelectedTimeEntriesThead"
        >
        </dropdown-configurator>
      </div>
      <div
          :style="docxButton || pdfButton || unsettleButton ? 'margin-top: 10px' : ''"
          class="d-flex justify-content-between"
      >
        <div>
          <el-tag
              class="mr-1"
              size="small"
              type="primary"
          >
            <i class="el-icon-goods"></i> {{ countOfSelectedTimeEntries }}
          </el-tag>
          <el-tag
              size="small"
              type="success"
          >
            <i class="el-icon-time"></i> {{ secondsToHHHmm(timeSumOfSelectedTimeEntries) }}
          </el-tag>
        </div>
        <div>
          <el-dropdown
              size="small"
              v-if="docxButton"
          >
            <el-button
                :disabled="timeEntries.length === 0 && countOfSelectedTimeEntries === 0"
                @click.native="handleGenerateReport('pdf')"
                size="mini"
                type="primary"
            >PDF
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleGenerateReport('pdf', true)">{{ $t('system.landscape_mode') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
              size="small"
              v-if="pdfButton"
          >
            <el-button
                :disabled="timeEntries.length === 0 && countOfSelectedTimeEntries === 0"
                @click.native="handleGenerateReport('docx')"
                class="ml-1"
                size="mini"
                type="primary"
            >
              DOC
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleGenerateReport('docx', true)">
                {{ $t('system.landscape_mode') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-badge
              :hidden="countOfSelectedTimeEntries === 0"
              :value="countOfSelectedTimeEntries"
              class="item"
              v-if="unsettleButton"
          >
            <el-tooltip
                :content="$t('system.unsettle_times')"
                class="item"
                effect="dark"
                placement="bottom"
            >
              <el-button
                  :disabled="countOfSelectedTimeEntries === 0"
                  @click.native="handleUnsettleTimeEntries"
                  class="ml-3"
                  size="mini"
                  type="danger"
              >
                <font-awesome-icon icon="undo"></font-awesome-icon>
              </el-button>
            </el-tooltip>
          </el-badge>
        </div>
      </div>
    </div>
    <checkable-table
        :align-left="['time_entries.comments']"
        :data="timeEntries"
        :editable-rows="['time_entries.overworked_time']"
        :isCheckedAll.sync="isCheckedAll"
        :limitColumnsWidth="[{column: 'time_entries.comments', maxWidth: '250px'}]"
        :pageSizes="[10, 30, 50, 100]"
        :params.sync="params"
        :selectedIds="selectedTimeEntriesIds"
        :thead="selectedTimeEntriesThead"
        :total="totalTimeEntriesCount"
        @clearAllSelections="clearAllTimeEntriesSelections"
        @editCell="updateData"
        @fetchData="fetchData"
        @selectionChange="setTimeEntrySelectionChange"
        idColumn="time_entries.id"
        pagination
    >
    </checkable-table>
    <!--    <stash-modal v-if="showStash"-->
    <!--                 :timeEntriesIds="selectedTimeEntriesIds"-->
    <!--                 @close="showStash = false"></stash-modal>-->
  </div>
</template>

<script>
import {mapActions} from 'vuex';
// import moment from 'moment'
// import _ from 'lodash'
import swal from 'sweetalert2';
import CheckableTable from './checkableTable.vue';
import DropdownConfigurator from '../../components/DropdownConfigurator/crmDropdownConfigurator.vue';
import notification from '../../notification/notify';
import time from '../../mixin/time';
import fileGenerator from './fileGenerator';
import EventBus from '../../eventBus';
// import StashModal from './stash.vue'

export default {
  name: 'timeEntriesCard',
  mixins: [fileGenerator, time],
  props: {
    namespace: {
      type: String,
      required: true,
    },
    unsettleButton: {
      type: Boolean,
      default: false,
    },
    docxButton: {
      type: Boolean,
      default: false,
    },
    pdfButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CheckableTable,
    DropdownConfigurator,
    // StashModal
  },
  beforeMount() {
    this.fetchData();
    this.fetchIssueStatuses();
  },
  created() {
    EventBus.$on('CLOSE_ISSUE_MODAL', async () => {
      this.setTimeSumOfSelectedTimeEntries(await this.getTimeEntriesSum(this.selectedTimeEntriesIds).then((response) => {
        return response.data.timesSum;
      }));
      this.fetchData();
    });
  },
  beforeDestroy() {
    EventBus.$off('CLOSE_ISSUE_MODAL');
  },
  computed: {
    timeSumOfSelectedTimeEntries() {
      return this.$store.getters[this.namespace + '/timeSumOfSelectedTimeEntries'];
    },
    timeEtriesFilters() {
      return this.$store.getters[this.namespace + '/timeEtriesFilters'];
    },
    timeEntries() {
      return this.$store.getters[this.namespace + '/timeEntries'];
    },
    selectedTimeEntriesIds() {
      return this.$store.getters[this.namespace + '/selectedTimeEntriesIds'];
    },
    timeEntriesThead() {
      return this.$store.getters[this.namespace + '/timeEntriesThead'].map((item) => {
        item.label = this.$t(item.key);
        return item;
      });
    },
    isCheckedAll: {
      get() {
        return this.$store.getters[this.namespace + '/isCheckedAllTimeEntries'];
      },
      async set(value) {
        await this.$store.dispatch(this.namespace + '/selectAllTimeEntries', value);
      },
    },
    selectedTimeEntriesThead() {
      return this.$store.getters[this.namespace + '/selectedTimeEntriesThead'];
    },
    countOfSelectedTimeEntries() {
      return this.$store.getters[this.namespace + '/countOfSelectedTimeEntries'];
    },
    totalTimeEntriesCount() {
      return this.$store.getters[this.namespace + '/totalTimeEntriesCount'];
    },
    allTimeEntriesIds() {
      return this.$store.getters[this.namespace + '/allTimeEntriesIds'];
    },
    params: {
      get() {
        return this.$store.getters[this.namespace + '/timeEntriesListParams'];
      },
      async set(value) {
        await this.$store.dispatch(this.namespace + '/setTimeEntriesListParams', value);
        this.fetchData();
      },
    },
    searchString: {
      get() {
        return this.$store.getters[this.namespace + '/timeEntriesListSearchString'];
      },
      async set(value) {
        await this.$store.dispatch(this.namespace + '/setTimeEntriesListSearchString', value);
      },
    },
  },
  methods: {
    ...mapActions('settled_reports', [
      'unsettleTimeEntries',
      'removeSelectedTimeEntriesIds',
      'fetchSettlements',
      'removeSelectedSettlementsIds',
      'clearTimeSumOfSelectedTimeEntries',
      'updateTimeEntryData',
    ]),
    setFiltersAndFetchData(filters) {
      this.setFilters(filters);
      // this.params.page = 1;
      this.fetchData();
    },
    async setTimeSumOfSelectedTimeEntries(seconds) {
      return this.$store.dispatch(this.namespace + '/setTimeSumOfSelectedTimeEntries', seconds);
    },
    clearTimeEntriesFilters() {
      this.$store.dispatch(this.namespace + '/clearTimeEntriesFilters');
      this.fetchData();
    },
    async handleGenerateReport(type, landscape = false) {
      let ids = [];
      if (this.countOfSelectedTimeEntries === 0) {
        ids = this.allTimeEntriesIds;
      } else {
        ids = this.selectedTimeEntriesIds;
      }

      const requestFilters = {};
      this.timeEtriesFilters.forEach(item => {
        if (item.type === 'checkbox' || item.type === 'switch') {
          requestFilters[item.name] = !!item.value;
        } else {
          requestFilters[item.name] = item.value;
        }
      });

      if (ids.length > 200) {
        swal.fire({
          title: this.$t('system.if_you_sure'),
          text: '200 ' + this.$t('system.max_rows'),
          type: 'info',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: this.$t('system.yes'),
          cancelButtonText: this.$t('system.no'),
        }).then((result) => {
          if (result.value) {
            this.generateReport(ids, this.selectedTimeEntriesThead, type, landscape, this.params.column, this.params.direction, requestFilters).then((response) => {
              const header = response.headers['content-disposition'];
              let name = header.slice(header.indexOf('=') + 1, header.length).toString();
              if (name.startsWith('"')) {
                name = name.replace('"', '');
              }
              this.downloadFile(response.data, type, name);
            });
          }
        });
      } else {
        this.generateReport(ids, this.selectedTimeEntriesThead, type, landscape, this.params.column, this.params.direction, requestFilters).then((response) => {
          const header = response.headers['content-disposition'];
          let name = header.slice(header.indexOf('=') + 1, header.length).toString();
          if (name.startsWith('"')) {
            name = name.replace('"', '');
          }
          this.downloadFile(response.data, type, name);
        });
      }
    },
    handleUnsettleTimeEntries() {
      swal.fire({
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      }).then(async (result) => {
        if (result.value) {
          const {data} = await this.unsettleTimeEntries();
          notification.notify(
              data.saved ? this.$t('notify.success') : this.$t('notify.error'),
              this.$t(data.message),
              data.type);
          if (data.saved) {
            await this.removeSelectedTimeEntriesIds(this.selectedTimeEntriesIds);
            await this.clearTimeSumOfSelectedTimeEntries();
            await this.fetchSettlements();
            this.removeSelectedSettlementsIds(data.deletedSettlementsIds);
            this.fetchTimeEntries();
          }
        }
      }).catch();
    },
    async fetchIssueStatuses() {
      await this.$store.dispatch(this.namespace + '/fetchIssueStatuses');
    },
    // async handeleSettlingTimeEntries () {
    //   const {data} = await this.settleTimeEntries(this.newSettlementName);
    //   notification.notify(
    //     data.saved ? this.$t('notify.success') : this.$t('notify.error'),
    //     this.$t(data.message),
    //     data.type);
    //   this.toggleNewSettlementForm();
    //   this.clearAllTimeEntriesSelections();
    //   this.fetchData()
    // },
    async clearAllTimeEntriesSelections() {
      await this.$store.dispatch(this.namespace + '/clearAllTimeEntriesSelections');
    },
    async setCurrentColumnsConfig(config) {
      await this.$store.dispatch(this.namespace + '/setCurrentColumnsConfig', config);
    },
    async setTimeEntrySelectionChange(id) {
      await this.$store.dispatch(this.namespace + '/setTimeEntrySelectionChange', id);
    },
    async fetchTimeEntries() {
      await this.$store.dispatch(this.namespace + '/fetchTimeEntries');
    },
    setFilters(filters) {
      this.$store.dispatch(this.namespace + '/setFilters', {filters});
    },
    async setSelectedTimeEntriesThead(columns) {
      await this.$store.dispatch(this.namespace + '/setSelectedTimeEntriesThead', columns);
      await this.fetchData();
    },
    async fetchData() {
      await this.fetchTimeEntries();
    },
    async updateData(data) {
      await this.updateTimeEntryData(data);
      await this.fetchData();
    },
  },
};
</script>
