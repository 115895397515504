import axios from 'axios';
import moment from 'moment';

export default {
  methods: {
    async generateReport(ids, columns, type, landscape, column, direction, filters) {
      return axios.post('report/generate', {
        ids,
        columns,
        type,
        orientation: landscape ? 'landscape' : 'portrait',
        column,
        direction,
        ...filters,
      }, {
        responseType: 'blob',
      });
    },
    downloadFile(file, type, filename = null) {
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.style = 'display: none';
      const url = window.URL.createObjectURL(new Blob([file]));
      link.href = url;
      link.download = filename || ('report ' + moment().format('DD-MM-YYYY hh:mm:ss') + '.' + type);
      // document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    },
  },
};
